import React, { useEffect, useState } from 'react';
import { graphql } from 'relay-runtime';
import '../../../stylesheets/components/globals.module.scss';
import '../../../stylesheets/components/theme.module.scss';
import '../../../stylesheets/components/buttonGlobals.module.scss';
import '../../../stylesheets/components/dropdownMenus.module.scss';
import '../../../stylesheets/components/dialog.module.scss';
import Text from '../tenant_portal/components/Text';
import { FieldsDeepUpdate } from '../typescript/types/FieldComponentProps';
import { updateDeep } from '../components/fields/FieldTools';
import PortalButton from '../components/PortalButton';
import PortalAuxButton from '../components/PortalAuxButton';
import { useMutation } from 'react-relay';
import { EmailLoginInitiateMutation$data } from './__generated__/EmailLoginInitiateMutation.graphql';
import { ErrorType } from '../components/Types';
import SubmitSpinner from '../components/SubmitSpinner';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../tenant_portal/PageHeader';
import {
  camelCaseToTitle,
  formatErrorMessages,
  filterErrorsByPath,
} from '../components/Tools';
import Alert from '../components/Alert';

export const LoginInitiateMutation = graphql`
  mutation EmailLoginInitiateMutation($input: LoginInitiateInput!) {
    loginInitiate(input: $input) {
      errors {
        path
        messages
      }
    }
  }
`;

const initialData = {
  email: '',
  password: '',
};

const Email = () => {
  const navigate = useNavigate();
  const [redirecting, setRedirecting] = useState(false);
  const [emailLoginFields, setEmailLoginFields] = useState(initialData);
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [commitMutation, isMutationInFlight] = useMutation(
    LoginInitiateMutation
  );

  const generalErrors = filterErrorsByPath(
    errors,
    ['email', 'password'],
    false
  );
  let formattedErrorMessages = '';
  generalErrors.forEach((error) => {
    const relPath = error.path.split('.').slice(-1)[0];
    const label = relPath === 'base' ? '' : camelCaseToTitle(relPath);
    formattedErrorMessages += formatErrorMessages([error], label);
  });

  const handleEmailLoginFieldsChange: FieldsDeepUpdate = (fieldName, value) => {
    setEmailLoginFields((prevFields) => {
      return updateDeep(prevFields, fieldName, value);
    });
  };

  const handleForgotPassword = () => {
    navigate('../forgot-password');
  };

  const handleSubmit = () => {
    const blankErrors: ErrorType[] = [];
    Object.entries(emailLoginFields).forEach(([key, value]) => {
      if (value === '') {
        blankErrors.push({
          path: key,
          messages: ["can't be blank"],
        });
      }
    });

    if (blankErrors.length > 0) {
      setErrors(blankErrors);
      return;
    }

    commitMutation({
      variables: {
        input: {
          login: {
            email: emailLoginFields.email,
            password: emailLoginFields.password,
          },
        },
      },
      onCompleted: (response) => {
        const responseData = response as EmailLoginInitiateMutation$data;
        if (!responseData || !responseData.loginInitiate) {
          setErrors([
            {
              path: 'base',
              messages: [
                'There was an error logging in. Please contact Property Matrix support for assistance.',
              ],
            },
          ]);
          setRedirecting(false);
          return;
        }
        if (
          responseData.loginInitiate.errors &&
          responseData.loginInitiate.errors.length > 0
        ) {
          setErrors(responseData.loginInitiate.errors as ErrorType[]);
          setRedirecting(false);
          return;
        }
        // If user is logged in and goes to login page, it uses LoginsController.redirect_if_logged_in to redirect them to the right portal
        window.location.href = window.RAILS_CONSTANTS['login_path'];
      },
      onError: (error) => {
        setErrors([
          {
            path: 'base',
            messages: [
              'There was an error logging in. Please contact Property Matrix support for assistance.',
            ],
          },
        ]);
        setRedirecting(false);
        console.error(error);
      },
    });
  };

  const onEnterDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSubmit();
  };

  useEffect(() => {
    if (errors.length > 0) handleEmailLoginFieldsChange('password', '');
  }, [errors]);

  if (isMutationInFlight || redirecting) {
    if (!redirecting) setRedirecting(true);
    return <SubmitSpinner spinnerText={'Logging in'} />;
  }

  return (
    <>
      <PageHeader title="Log in" />
      {generalErrors.length > 0 && (
        <Alert severity="error">{formattedErrorMessages}</Alert>
      )}
      <form>
        <Text
          field={{
            label: 'Email',
            name: 'email',
            type: 'email',
            value: emailLoginFields.email,
            autocomplete: 'username',
            autoFocus: true,
          }}
          mode="edit"
          onChange={handleEmailLoginFieldsChange}
          onKeyDown={onEnterDown}
          errors={filterErrorsByPath(errors, ['email'])}
        />
        <Text
          field={{
            label: 'Password',
            name: 'password',
            value: emailLoginFields.password,
            type: 'password',
            autocomplete: 'current-password',
          }}
          mode="edit"
          onChange={handleEmailLoginFieldsChange}
          onKeyDown={onEnterDown}
          errors={filterErrorsByPath(errors, ['password'])}
        />
      </form>
      <PortalButton id="sign-in-button" onClick={handleSubmit}>
        Sign in
      </PortalButton>
      <PortalAuxButton onClick={handleForgotPassword}>
        Forgot your password?
      </PortalAuxButton>
    </>
  );
};

export default Email;
